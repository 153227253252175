import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type {
    CompletionHistory,
    DivisionHistoryType,
    RequiredHistory
} from '@pi/pi-common/src/ui-dtos/division-blobs.dtos';
import type {
    DivisionInsert,
    DivisionPaginatedResponseDTO,
    DivisionResponse,
    DivisionUpdate
} from '@pi/pi-common/src/ui-dtos/division.dtos';
import type { PaginatedDTO } from '@pi/pi-common/src/ui-dtos/dto';
import type { ErrorDTO } from '@pi/pi-common/src/ui-dtos/error.dtos';
import type { Id } from '@pi/pi-common/src/ui-dtos/id';
import type { SupplementalDataData, SupplementalDataResponse } from '@pi/pi-common/src/ui-dtos/supplemental-data.dtos';
import type { VerifyDivisionResponse } from '@pi/pi-common/src/ui-dtos/verify-division.dtos';
import type { DbInt8 } from '@pi/ridenour-essentials/zapatos.helpers';
import type { DateTime } from 'luxon';
import type { LazyLoadEvent } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { Md5 } from 'ts-md5/dist/cjs';

import { API_PATH, DataService } from './data.service';
import { removeUndefinedProps } from '../../shared/functions/remove-undefined-props';
import { DivisionCache } from '../../shared/services/division-cache.service';
import type { CachedDivision } from '../../shared/types/cached-division';
import type { TablePaginationMetadata } from '../../shared/types/table-pagination-metadata';
import type {
    DivisionForTable,
    IDivisionRes,
    ILinkToPlatformRes,
    ISubmitDivisionForApprovalRes
} from '../interfaces/division';
import type { IDivisionBlobsRes } from '../interfaces/division-blob';

type SortableTableColumns = 'name' | 'id' | 'plan_sponsor_name';

interface PayrollOptions {
    isBackfill?: boolean;
    isTestMode?: boolean;
    skipSFTPDelivery?: boolean;
    skipOutput?: boolean;
}
export interface CheckForPayrollOptions extends PayrollOptions {
    specifiedStartDate?: string;
    specifiedEndDate?: string;
    invalidateExisting?: boolean;
}

export interface InvalidatePayrollOptions extends PayrollOptions {
    payrollId: DbInt8;
    rerun?: boolean;
}

export interface BackfillPayrollOptions {
    isBackfill: true;
    specifiedStartDate: string;
    specifiedEndDate: string;
    invalidateExisting: boolean;
}

type ManagePayrollOptions = CheckForPayrollOptions | InvalidatePayrollOptions | BackfillPayrollOptions;

@Injectable({
    providedIn: 'root'
})
export class DivisionService {
    public static readonly DivisionSetupKey: DivisionHistoryType = 'divisionSetupComplete';
    public static readonly LoanRequirementKey: DivisionHistoryType = 'divisionLoansRequirement';
    public static readonly FilterRequirementKey: DivisionHistoryType = 'divisionEmployeeFilterRequirement';
    public static readonly EligibilityRequirementKey: DivisionHistoryType = 'divisionEmployeeEligibilityRequirement';

    private basePath = API_PATH.divisions;

    private readonly dataService = inject(DataService);
    private readonly divisionCache = inject(DivisionCache);
    private readonly document = inject<Document>(DOCUMENT);
    private readonly http = inject(HttpClient);

    public getNumberOfPlanSponsorDivisions(planSponsorId: DbInt8): Promise<number> {
        return this.dataService.genericGET(this.planSponsorBasePath(planSponsorId));
    }

    public async loadDivision(planSponsorId: DbInt8, divisionId: DbInt8, useCache = false): Promise<DivisionResponse> {
        const response = await this.dataService.genericGET(this.divisionPath(planSponsorId, divisionId));

        this.divisionCache.set(response.id, response);

        return response;
    }

    public getPaginatedDivisions(
        lazyEvent: LazyLoadEvent,
        planSponsorId?: DbInt8,
        divisionId?: DbInt8
    ): Promise<DivisionPaginatedResponseDTO | ErrorDTO> {
        let queryParams = this.dataService.getQueryParamsFromLazyEvent(lazyEvent);
        if (planSponsorId) {
            queryParams = queryParams.append('planSponsorId', planSponsorId);
        }
        if (divisionId) {
            queryParams = queryParams.append('divisionId', divisionId);
        }
        return this.dataService.genericGET(this.dataService.addQueryParametersToPath(this.basePath, queryParams));
    }

    public async loadPaginatedDivisions(
        planSponsorId: Id | undefined,
        divisionId: Id | undefined,
        event: TablePaginationMetadata
    ): Promise<PaginatedDTO<DivisionForTable>> {
        let params = new HttpParams({ fromObject: removeUndefinedProps(event) });
        if (planSponsorId) {
            params = params.append('planSponsorId', planSponsorId);
        }
        if (divisionId) {
            params = params.append('divisionId', divisionId);
        }
        const response = await lastValueFrom(
            this.http.get<PaginatedDTO<DivisionForTable>>(this.basePath + '?' + params.toString())
        );
        if (planSponsorId) {
            for (const division of response.data) {
                this.divisionCache.set(division.id, {
                    id: division.id,
                    planSponsorId: division.plan_sponsor_id,
                    name: division.name
                });
            }
        }

        return { data: response.data, meta: response.meta };
    }

    /**
     * @param planSponsorId
     * @param divisionId
     * @param useCache  [useCache=false] - Determines whether it should check the UI cache before attempting to make an API call
     */
    public async getById(planSponsorId: Id, divisionId: Id, useCache: true): Promise<CachedDivision>;
    public async getById(planSponsorId: Id, divisionId: Id, useCache?: false | undefined): Promise<DivisionResponse>;
    public async getById(
        planSponsorId: Id,
        divisionId: Id,
        useCache = false
    ): Promise<CachedDivision | DivisionResponse> {
        if (useCache) {
            const cachedDivision = this.divisionCache.get(divisionId);
            if (cachedDivision) {
                return cachedDivision;
            }
        }

        return this.dataService.genericGET(this.divisionPath(planSponsorId, divisionId));
    }

    public patchDivision(planSponsorId: DbInt8, divisionId: DbInt8, dto: DivisionUpdate): Promise<any> {
        return this.dataService.genericPATCH(this.divisionPath(planSponsorId, divisionId), dto);
    }

    public createDivision(planSponsorId: DbInt8, dto: DivisionInsert): Promise<IDivisionRes> {
        return this.dataService.genericPOST(this.planSponsorBasePath(planSponsorId), dto);
    }

    public linkToPlatform(planSponsorId: DbInt8, divisionId: DbInt8, redirectURI?: string): any {
        let path = `${this.divisionPath(planSponsorId, divisionId)}/linkToPlatform`;
        if (redirectURI) {
            path = `${path}?redirectURI=${encodeURIComponent(redirectURI)}`;
        }
        return this.dataService.genericGET(path).then((result) => {
            if ((result as ILinkToPlatformRes).redirectURI) {
                this.document.location.href = result.redirectURI;
            }
        });
    }

    public disconnectFromPlatform(planSponsorId: DbInt8, divisionId: DbInt8): any {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/disconnectFromPlatform`;
        return this.dataService.genericPOST(path, {});
    }

    public verifyDivision(planSponsorId: DbInt8, divisionId: DbInt8): Promise<VerifyDivisionResponse> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/verifyDivision`;
        return this.dataService.genericGET(path);
    }

    public submitForApproval(planSponsorId: DbInt8, divisionId: DbInt8): Promise<ISubmitDivisionForApprovalRes> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/submitForApproval`;
        return this.dataService.genericGET(path);
    }

    public approveDivision(planSponsorId: DbInt8, divisionId: DbInt8): Promise<boolean> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/approve`;
        return this.dataService.genericPOST(path, {});
    }

    public getSupplementalData(planSponsorId: DbInt8, divisionId: DbInt8): Promise<SupplementalDataResponse> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/supplementalData`;
        return this.dataService.genericGET(path);
    }

    public saveSupplementalData(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        dto: SupplementalDataData
    ): Promise<SupplementalDataResponse> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/supplementalData`;
        return this.dataService.genericPUT(path, dto);
    }

    public checkForPayroll(planSponsorId: DbInt8, divisionId: DbInt8, options: CheckForPayrollOptions): Promise<any> {
        return this.managePayroll(planSponsorId, divisionId, options);
    }

    public invalidatePayroll(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        options: InvalidatePayrollOptions
    ): Promise<any> {
        return this.managePayroll(planSponsorId, divisionId, options);
    }

    public runBackfill(planSponsorId: DbInt8, divisionId: DbInt8, options: BackfillPayrollOptions): Promise<any> {
        return this.managePayroll(planSponsorId, divisionId, options);
    }

    public runComplianceReport(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        startDate: DateTime,
        endDate: DateTime,
        format: string
    ): Promise<any> {
        const start = startDate.toISODate();
        const end = endDate.toISODate();
        const path = `${this.divisionPath(
            planSponsorId,
            divisionId
        )}/complianceReport?startDate=${start}&endDate=${end}&format=${format}`;
        return this.dataService.genericPOST(path, {});
    }

    public runDeferralSync(planSponsorId: number, divisionId: number): Promise<boolean> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/invokePaycodeStateRetriever`;
        return this.dataService.genericPOST(path, {});
    }

    public history(planSponsorId: DbInt8, divisionId: DbInt8): Promise<Array<CompletionHistory | RequiredHistory>> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/blobs`;
        return this.dataService
            .genericGET(path)
            .then((result: IDivisionBlobsRes<CompletionHistory | RequiredHistory>) => {
                if (result.blobs) {
                    const history = result.blobs
                        .filter(
                            (blob) =>
                                blob.data &&
                                (blob.blob_key === DivisionService.DivisionSetupKey ||
                                    blob.blob_key.startsWith(DivisionService.LoanRequirementKey) ||
                                    blob.blob_key.startsWith(DivisionService.FilterRequirementKey) ||
                                    blob.blob_key.startsWith(DivisionService.EligibilityRequirementKey))
                        )
                        .map((blob) => blob.data);
                    if (history) {
                        return history;
                    }
                }
                return [];
            })
            .catch(
                // Passive endpoint: error signifies no history so we don't throw any error
                () => []
            );
    }

    public markCompletionStatus(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        completed: boolean
    ): Promise<CompletionHistory> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/blobs/${DivisionService.DivisionSetupKey}`;
        const dto: CompletionHistory = {
            divisionId,
            setupCompleted: completed,
            key: DivisionService.DivisionSetupKey
        };
        return this.dataService.genericPUT(path, dto).catch(
            () =>
                // Passive endpoint: error does not impact functionality
                null
        );
    }

    public markLoansRequirement(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        required: boolean,
        groupCode?: string
    ): Promise<RequiredHistory> {
        const key = `${DivisionService.LoanRequirementKey}${groupCode ? '-' + Md5.hashStr(groupCode) : ''}`;
        const path = `${this.divisionPath(planSponsorId, divisionId)}/blobs/${key}`;
        const dto: RequiredHistory = {
            divisionId,
            groupCode,
            required: required,
            key: DivisionService.LoanRequirementKey
        };
        return this.dataService.genericPUT(path, dto).catch(
            () =>
                // Passive endpoint: error does not impact functionality
                null
        );
    }

    public markEmployeeFiltersRequirement(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        required: boolean,
        groupCode?: string
    ): Promise<RequiredHistory> {
        const key = `${DivisionService.FilterRequirementKey}${groupCode ? '-' + Md5.hashStr(groupCode) : ''}`;
        const path = `${this.divisionPath(planSponsorId, divisionId)}/blobs/${key}`;
        const dto: RequiredHistory = {
            divisionId,
            groupCode,
            required: required,
            key: DivisionService.FilterRequirementKey
        };
        return this.dataService.genericPUT(path, dto).catch(
            () =>
                // Passive endpoint: error does not impact functionality
                null
        );
    }

    public markEmployeeEligibilityRequirement(
        planSponsorId: DbInt8,
        divisionId: DbInt8,
        required: boolean,
        groupCode?: string
    ): Promise<RequiredHistory> {
        const key = `${DivisionService.EligibilityRequirementKey}${groupCode ? '-' + Md5.hashStr(groupCode) : ''}`;
        const path = `${this.divisionPath(planSponsorId, divisionId)}/blobs/${key}`;
        const dto: RequiredHistory = {
            divisionId,
            groupCode,
            required: required,
            key: DivisionService.EligibilityRequirementKey
        };
        return this.dataService.genericPUT(path, dto).catch(
            () =>
                // Passive endpoint: error does not impact functionality
                null
        );
    }

    public planSponsorBasePath(planSponsorId: DbInt8): string {
        return `planSponsors/${planSponsorId}/divisions/`;
    }

    public divisionPath(planSponsorId: DbInt8, divisionId: DbInt8): string {
        return this.planSponsorBasePath(planSponsorId) + divisionId;
    }

    private managePayroll(planSponsorId: DbInt8, divisionId: DbInt8, options: ManagePayrollOptions): Promise<any> {
        const path = `${this.divisionPath(planSponsorId, divisionId)}/managePayroll`;
        const queryParameters = this.dataService.objectToHttpParams(
            options as Record<string, string | number | boolean>
        );
        return this.dataService.genericPOST(this.dataService.addQueryParametersToPath(path, queryParameters), {});
    }
}

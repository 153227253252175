import { Injectable } from '@angular/core';
import type { Id } from '@pi/pi-common/src/ui-dtos/id';

import { LOGOUT_TEARDOWN_TOKEN } from '../tokens/logout-teardown-token';
import type { CachedDivision } from '../types/cached-division';

@Injectable({ providedIn: 'root' })
export class DivisionCache extends Map<Id, CachedDivision> {}

export const DIVISION_CACHE_TEARDOWN = {
    provide: LOGOUT_TEARDOWN_TOKEN,
    multi: true,
    deps: [DivisionCache],
    useFactory: (divisionCache: DivisionCache) => () => divisionCache.clear()
};
